@charset "UTF-8";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-family: 'Noto Sans JP', "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "Arial", "メイリオ", Meiryo, "Helvetica Neue", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-break: strict;
  min-width: 1000px;
}
@media all and (-ms-high-contrast:none){
  /* IE10+ only */
  body {
    font-family: "Arial" ,Meiryo, sans-serif;
  }
}

ul,ol {
  list-style-type: none;
}
p {
}
a {
  color: inherit;
  text-decoration: none;
}
img {
  max-width: 100%;
}
img[src$=".svg"] {
  width: 100%;
}

.pc {
  display: block;
}
.sp {
  display: none;
}
input[type="submit"],input[type="button"] {
  -webkit-appearance: none;
}
input[type="text"],input[type="email"],input[type="tel"],textarea {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
}


@font-face {
  font-family: fa;
  src: url("/common/iconfonts/fa-brands-400.eot");
  src: url('/common/iconfonts/fa-brands-400.svg') format('svg'),
  url('/common/iconfonts/fa-brands-400.ttf') format('truetype'),
  url('/common/iconfonts/fa-brands-400.woff') format('woff'),
  url('/common/iconfonts/fa-brands-400.woff2') format('woff2');
}

@font-face {
  font-family: fa-regular;
  src: url("/common/iconfonts/fa-regular-400.eot");
  src: url("/common/iconfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
  url("/common/iconfonts/fa-regular-400.woff2") format("woff2"),
  url("/common/iconfonts/fa-regular-400.woff") format("woff"),
  url("/common/iconfonts/fa-regular-400.ttf") format("truetype"),
  url("/common/iconfonts/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
  font-family: fa-solid;
  src: url("/common/iconfonts/fa-solid-900.eot");
  src: url("/common/iconfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
  url("/common/iconfonts/fa-solid-900.woff2") format("woff2"),
  url("/common/iconfonts/fa-solid-900.woff") format("woff"),
  url("/common/iconfonts/fa-solid-900.ttf") format("truetype"),
  url("/common/iconfonts/fa-solid-900.svg#fontawesome") format("svg");
}

@media screen and (max-width:767px){
  .pc {
    display: none;
  }
  .sp {
    display: block;
  }
}
